<template>
  <div class="tariffs-table-tag">
    <div class="tariffs-table-tag__tags">
      <div v-for="tag in tags" :key="tag" class="tariffs-table__tags-item">
        <plain-button class="tariffs-table__prolong" @click="clickTag(tag)">
          <main-label
            :color="selectedTags.includes(tag) ? 'primary' : 'note'"
            class="tariff-card__label"
          >
            {{ tag }}
          </main-label>
        </plain-button>
      </div>
      <div v-if="!!selectedTags.length" class="tariffs-table__tags-reset">
        <plain-button color="primary" @click="resetTag()"> {{ $t('reset') }} </plain-button>
      </div>
    </div>
    <div class="tariffs-table-tag__search">
      <!--      <div class="col-12 md:col-4">-->
      <div>
        <div v-if="searchType === 'server'" class="p-inputgroup">
          <SelectButton
            v-model="searchBy"
            :options="searchByType"
            option-label="name"
            option-value="name"
            class="select-button"
          >
            <template #option="slotProps">
              <div class="car-option">
                <div>{{ $t(slotProps.option.name) }}</div>
              </div>
            </template>
          </SelectButton>
          <span class="p-float-label p-input-icon-left search">
            <i class="pi pi-search" />
            <InputText
              id="search"
              :placeholder="searchName"
              v-model="searchList"
              type="text"
              class="search"
            />
            <!--              :type="searchBy === 'name' ? 'text' : 'number'"-->
            <!--            <label for="search">Поиск</label>-->
          </span>
          <!--          <Button icon="pi pi-times" class="p-button-danger" />-->
        </div>
        <div v-else>
          <span class="p-float-label p-input-icon-left search">
            <i class="pi pi-search" />
            <InputText id="searchSolo" v-model="searchList" type="text" class="search" />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import MainLabel from '@/components/Labels/MainLabel';
import InputText from 'primevue/inputtext';
import SelectButton from 'primevue/selectbutton';
export default {
  name: 'TagsFilter',
  components: { MainLabel, InputText, SelectButton },
  mixins: [setFocus],
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
    searchType: {
      type: String,
      default: '',
    },
    search: {
      type: String,
      default: () => '',
    },
    selectedTags: {
      type: Array,
      default: () => [],
    },
    activeSearch: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      searchList: '',
      searchBy: 'name',
      searchByType: [
        { name: 'name', code: 'n' },
        { name: 'ip', code: 'i' },
      ],
    };
  },
  computed: {
    searchName() {
      return this.searchBy === 'name' ? 'Поиск по имени сервера' : 'Поиск по IP сервера';
    },
  },
  watch: {
    searchList: {
      handler: function (event) {
        if (event) this.$emit('search-server', event);
        else this.$emit('search-server', '');
      },
    },
    searchBy: {
      handler: function (event) {
        if (event) this.$emit('search-type', event);
      },
    },
  },
  mounted() {
    this.searchList = this.search;
  },

  methods: {
    clickTag(event) {
      this.$emit('click-tag', event);
    },
    resetTag() {
      this.$emit('reset');
      // this.selectedTags = [];
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "reset": "Сбросить все теги",
    "name": "Имя",
    "ip": "IP"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.select-button {
  display: flex;
}
.p-button {
  color: #007bff;
  background: #fff;
  border: 1px solid #007bff;
  padding: .5rem .75rem;
  font-size: 1rem;
  transition: background-color .15s,border-color .15s,box-shadow .15s;
  border-radius: 4px
}
.p-selectbutton .p-button .p-button-icon-left, .p-selectbutton .p-button .p-button-icon-right {
  color: #6c757d;
}

.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
  background: #e9ecef;
  border-color: #ced4da;
  color: #495057;
}

.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left, .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
  color: #343a40;
}

.p-selectbutton .p-button.p-highlight {
  background: #3B82F6;
  border-color: #3B82F6;
  color: #ffffff;
}

.p-selectbutton .p-button.p-highlight .p-button-icon-left, .p-selectbutton .p-button.p-highlight .p-button-icon-right {
  color: #ffffff;
}

.p-selectbutton .p-button.p-highlight:hover {
  background: #2563eb;
  border-color: #2563eb;
  color: #ffffff;
}

.p-selectbutton .p-button.p-highlight:hover .p-button-icon-left, .p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
  color: #ffffff;
}

.p-selectbutton.p-invalid > .p-button {
  border-color: #e24c4c;
}
.tariffs-table-tag {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  &__tags {
    margin: 1.25rem 0;
    display flex
    align-items: center;
    flex-wrap: wrap;

    &-item {
      margin: 0.5rem 0 0.5rem 0.5rem

    }
    &-reset {
      margin: 0.5rem 0 0.5rem 0.5rem
    }
  }

  &__search {
    min-width: 25%
  }

}
  .search {
    width: 100%
  }
</style>
